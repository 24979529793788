<template>
<div>
    <main class="main">
            <nav aria-label="breadcrumb" class="breadcrumb-nav" >
                <div class="container">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="/">Home</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Product</li>
                    </ol>
                </div>
            </nav>

            <div class="container" dir="rtl">
                <div class="row row-sm">
                    <div class="col-lg-9 col-xl-10">
                        <div class="product-single-container product-single-default">
                            <div class="row">
                                <div class="col-lg-4 col-md-6 product-single-gallery">
                                    <div>
                                        <vueper-slides
                                            ref="vueperslides1"
                                            :touchable="false"
                                            fade
                                            :autoplay="false"
                                            :bullets="false"
                                            @slide="$refs.vueperslides2.goToSlide($event.currentSlide.index, { emit: false })"
                                            fixed-height="400px">
                                        <vueper-slide
                                          v-for="(slide, i) in slides"
                                          :key="i"
                                          :image="slide.image"/>
                                        </vueper-slides>            
                                        <vueper-slides
                                          class="no-shadow thumbnails"
                                          ref="vueperslides2"
                                          @slide="$refs.vueperslides1.goToSlide($event.currentSlide.index, { emit: false })"
                                          :visible-slides="slides.length"
                                          fixed-height="150px"
                                          :bullets="false"
                                          :touchable="false"
                                          :gap="2.5"
                                          :arrows="false">
                                          <vueper-slide
                                            v-for="(slide, i) in slides"
                                            :key="i"
                                            :image="slide.image"
                                            @click.native="$refs.vueperslides2.goToSlide(i)">
                                          </vueper-slide>
                                        </vueper-slides>
                                    </div>
                                </div><!-- End .col-lg-5 -->

                                <div class="col-lg-8 col-md-6">
                                    
                                    <div class="product-single-details" style="text-align: right;">
                                        <h1 class="product-title">{{ product.nameAr }}</h1>

                                        <div class="ratings-container">
                                            <div class="product-ratings">
                                                <span class="ratings" style="width:100%"></span><!-- End .ratings -->
                                            </div><!-- End .product-ratings -->

                                            <a href="#" class="rating-link">( {{ product.rating }} )</a>
                                        </div><!-- End .product-container -->

                                        <div class="price-box" v-if="product.isSale">
											<span class="old-price">{{product.price+" LYD"}} </span>
											<span class="product-price">{{ product.salePrice+" LYD" }} </span>
										</div>
                                        <div class="price-box" v-else>
                                            <span class="product-price">{{ product.price }} LYD</span>
                                        </div><!-- End .price-box -->

                                        <div class="product-desc">
                                            <p> {{ product.sku   }} </p>
                                            <p id="short-description"> {{ product.shortDescription }} </p>
                                        </div><!-- End .product-desc -->
 
                                    
                                        <div style="color: red; margin-right: 13px;">{{error}}</div>
                                        <div class="product-action product-all-icons">
                                            <a v-if="product.stockQuantity > 0 && token" @click.prevent="addToCart" class="paction add-cart text-white" title="Add to Cart"> 
                                                <div id="btnText">اضافة الى السلة</div> 
                                                <font-awesome-icon v-if="spinner" icon="spinner" spin />
                                            </a>
                                            <a v-else-if="!token" v-b-modal.modal-1 class="paction add-cart text-white" title="Add to Cart">اضافة الى السلة</a>
                                            
                                            <div class="product-action" style="margin-bottom: 12px; margin-right: 18px; ">
                                                <div v-if="token">
                                                    <a href="" v-if="favorites.some(item => item.productId === product.id)"  :id="'wishlist_product'"  @click.prevent="wishlist(true,product.id)" class="btn-icon-wish">
                                                        <div class="heart"></div>
                                                    </a>
                                                    <a v-else href="" @click.prevent="wishlist(false,product.id)" :id="'wishlist_product'" class="btn-icon-wish">
                                                        <i class="icon-heart"></i>
                                                    </a>
                                                </div>
                                                <div v-else>
                                                    <a class="btn-icon-wish" v-b-modal.modal-1><i class="icon-heart"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div><!-- End .product-single-details -->
                                </div><!-- End .col-lg-7 -->
                            </div><!-- End .row -->
                        </div><!-- End .product-single-container -->

                        <div class="product-single-tabs">
                            <ul class="nav nav-tabs" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link active" id="product-tab-desc" data-toggle="tab" href="#product-desc-content" role="tab" aria-controls="product-desc-content" aria-selected="true">الوصف</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="product-tab-reviews" data-toggle="tab" href="#product-reviews-content" role="tab" aria-controls="product-reviews-content" aria-selected="false">التقييم</a>
                                </li>
                            </ul>
                            <div class="tab-content" style="text-align: right;">
                                <div class="tab-pane fade show active" id="product-desc-content" role="tabpanel" aria-labelledby="product-tab-desc">
                                    <div class="product-desc-content">
                                        <p>
                                            {{ product.longDescription }}
                                        </p>
                                    </div><!-- End .product-desc-content -->
                                </div><!-- End .tab-pane -->

                                <div class="tab-pane fade" id="product-reviews-content" role="tabpanel" aria-labelledby="product-tab-reviews">
                                    <div class="product-reviews-content">
                                        <br>
                                        <div class="add-product-review" style="    background-color: white; padding: 20px; border-radius: 14px;">
                                            <h3 class="text-uppercase heading-text-color font-weight-semibold">ما رايك بالمنتج</h3>
                                            <p> ملاحظتك وتقيمك مهم لنا .  </p>
                                            
                                            <form @submit.prevent="post_review">
                                                <div>
                                                    <div class="rate">
                                                        <input type="radio" v-model="rate" id="star5" value="5" >
                                                        <label for="star5" title="5">5 </label>
                                                        <input type="radio" v-model="rate" id="star4" value="4" >
                                                        <label for="star4" title="4">4 </label>
                                                        <input type="radio" v-model="rate" id="star3" value="3" >
                                                        <label for="star3" title="3">3 </label>
                                                        <input type="radio" v-model="rate" id="star2" value="2" >
                                                        <label for="star2" title="2">2 </label>
                                                        <input type="radio" v-model="rate" id="star1" value="1" >
                                                        <label for="star1" title="1">1 </label>
                                                    </div>
                                                </div>
                                                <div class="form-group mb-2">
                                                    <label>ملاحظاتك <span class="required"></span></label>
                                                    <textarea id="details" v-model="details" cols="3" rows="3" class="form-control form-control-sm"></textarea>
                                                </div>
                                                <div v-if="token">   
                                                    <input type="submit" class="btn btn-primary send-btn" value="ارسل">
                                                </div>
                                                <div v-else>

                                                </div>
                                            </form>
                                            
                                        </div><!-- End .add-product-review -->
                                    </div><!-- End .product-reviews-content -->
                                </div><!-- End .tab-pane -->
                            </div><!-- End .tab-content -->
                        </div><!-- End .product-single-tabs -->
                    </div><!-- End .col-lg-9 -->

                    <div class="sidebar-toggle"><i class="icon-right-open"></i></div>
                    <aside class="sidebar-product col-lg-3 col-xl-2 padding-left-lg mobile-sidebar">
                        <div class="sidebar-wrapper">

                            <div class="widget">
                                <h3 class="widget-title">
                                    <a data-toggle="collapse" href="#widget-body-2" role="button" aria-expanded="true" aria-controls="widget-body-2">افضل خمس منتجات تم بيعها</a>
                                </h3>

                                <div class="collapse show" id="widget-body-2">
                                    <div class="widget-body">
                                        <div class="product-intro" v-for="(bestSeller, index) in bestSellers" :key="index">
                                            <div class="product-default left-details product-widget">
                                                <figure>
                                                    <a href="product.html">
                                                        <img :src="base_url+'/api/public/file/download/'+bestSeller.imageUrl">
                                                    </a>
                                                </figure>
                                                <div class="product-details">
                                                    <h2 class="product-title">
                                                        <a href="product.html">{{ bestSeller.name }}</a>
                                                    </h2>
                                                    <div class="ratings-container">
                                                        <div class="product-ratings">
                                                            <span class="ratings" style="width:100%"></span><!-- End .ratings -->
                                                            <span class="tooltiptext tooltip-top"></span>
                                                        </div><!-- End .product-ratings -->
                                                    </div><!-- End .product-container -->
                                                    <div class="price-box" v-if="bestSeller.isSale">
                                                        <span class="old-price">{{bestSeller.price}}LYD </span>
                                                        <span class="product-price">{{ bestSeller.salePrice+" LYD" }} </span>
                                                    </div>
                                                    <div class="price-box" v-else>
                                                        <span class="product-price">{{ bestSeller.price }} LYD</span>
                                                    </div><!-- End .price-box -->
                                                </div><!-- End .product-details -->
                                            </div>
                                        </div>
                                    </div><!-- End .widget-body -->
                                </div><!-- End .collapse -->
                            </div><!-- End .widget -->
                        </div>
                    </aside><!-- End .col-md-3 -->
                </div><!-- End .row -->
            </div><!-- End .container -->
    </main>
</div>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

export default {
    name: 'ProductDisplay',
    components: {
        VueperSlides, VueperSlide
    },
    data() {
        return {
            rate: 0, 
            base_url: process.env.VUE_APP_UR, 
            details: '', 
            productId : 0, 
            product : {}, 
            slides  : [], 
            bestSellers   : [], 
            favorites   : [], 
            token : '', 
            ratingData : false,
            spinner : false,
            error : "",
        }
    },
    mounted() {
        this.token = localStorage.getItem('token') ? localStorage.getItem('token') : null
        this.productId = this.$route.params.productId;
        this.axios
        .get(process.env.VUE_APP_URL+'/api/public/products/'+this.productId,{
                headers: {'Access-Control-Allow-Origin': '*'}
        })
        .then(response => {
            this.product  = response.data 
            console.log(this.product)
            document.getElementById("short-description").innerHTML = this.product.shortDescription;
            this.hasVariant = this.product.attributes.length == 0 ? false : true;
            this.getProductImages(this.product);
        })
        this.axios
        .get(process.env.VUE_APP_URL+'/api/public/products/best-seller?size=5',{ 
                headers: {'Access-Control-Allow-Origin': '*'} 
        }) 
        .then(response => { 
            this.bestSellers  = response.data 
        }) 
        this.getFavorites();
    },
    methods: {
        getProductImages: function(product) {
            if(product.productImages.length != 0) {
                product.productImages.forEach(element => {
                    this.slides.push({image : process.env.VUE_APP_URL+'/api/public/file/download/'+element.imageUrl})
                });
            } else {
                this.slides.push({image : process.env.VUE_APP_URL+'/api/public/file/download/'+product.imageUrl})
            }
        },
        
        addToCart: function() {
            document.getElementById("btnText").hidden = true
            this.spinner = true
            
            this.error = "";
            this.$store.dispatch('add_cart', this.product)
            .then(() => {
                this.$store.dispatch('getCartItems')
                document.getElementById("btnText").hidden = false
                this.spinner  = false
            }) 
            .catch(() => {
                document.getElementById("btnText").hidden = false
                this.error = "لقد قمت باضافة الكمية بالكامل لهذا المنتج";
                this.spinner  = false
            })
        },
        post_review: function() {
            var typeReq = "POST"
            if(this.ratingData) {
                typeReq = "PUT"
            }
            this.axios({
                url: process.env.VUE_APP_URL+'/api/product-reviews',
                data: {
                    details: this.details,
                    productId: this.product.id,
                    productName: this.product.name,
                    rate: this.rate,
                },
                method: typeReq,
                headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
            })
        },
        wishlist: function(status,productId) {
            this.favorites = [];
            this.getFavorites()
            if(status == true){
                document.getElementById("wishlist_product").innerHTML="";
                document.getElementById("wishlist_product").innerHTML=`<i style="font-size: 20px;" class="icon-heart"></i>`;
            }else {
                document.getElementById("wishlist_product").innerHTML="";
                document.getElementById("wishlist_product").innerHTML=`<div class="heart"></div>`;
            }
            this.$store.dispatch('wishlist', productId)
            this.getFavorites();
        },
        getFavorites: function() {
            if(localStorage.getItem('token')) {
                this.axios
                .get(process.env.VUE_APP_URL+'/api/favorites',{
                    headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
                })
                .then(response => {
                    this.favorites = response.data;
                })
            }
        }
    },
    
}
</script>

<style scoped>
    .add-wishlist:hover {
        border: none;
    }

    .thumbnails {
    margin: auto;
    max-width: 300px;
}

.thumbnails .vueperslide {
    box-sizing: border-box;
    border: 1px solid #fff;
    transition: 0.3s ease-in-out;
    opacity: 0.7;
    cursor: pointer;
}

.thumbnails .vueperslide--active {
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
    opacity: 1;
    border-color: #000;
} 
.vueperslides--fixed-height .vueperslides__parallax-wrapper {
    padding-bottom: 33.3333%;
}
.button-variant-active{
    border: 0px; 
    font-size: medium; 
    background-color: white; 
    color: black; 
    padding: 0px 13px;
    min-width: 12px; 
    cursor: pointer;
}
.button-variant-active:hover{
    background-color: #da466e;
    color: white;
    border: 2px solid #e7e7e7;
}
.button-variant-deactive{
    border: 0px; 
    font-size: medium; 
    text-decoration: line-through; 
    text-decoration-style: solid; 
    text-decoration-color: #f23333;  
    background-color: #d5d5d5; 
    padding: 0px 13px;
}

.rate {
    float: right;
    height: 46px;
    padding: 0 10px;
}
.rate:not(:checked) > input {
    position:fixed;
}
.rate:not(:checked) > label {
    float:left;
    width:1em;
    overflow:hidden;
    white-space:nowrap;
    cursor:pointer;
    font-size:30px;
    color:#ccc;
}
.rate:not(:checked) > label:before {
    content: '★ ';
}
.rate > input:checked ~ label {
    color: #ffc700;    
}
.rate:not(:checked) > label:hover,
.rate:not(:checked) > label:hover ~ label {
    color: #deb217;  
}
.rate > input:checked + label:hover,
.rate > input:checked + label:hover ~ label,
.rate > input:checked ~ label:hover,
.rate > input:checked ~ label:hover ~ label,
.rate > label:hover ~ input:checked ~ label {
    color: #c59b08;
}
i {
    font-style: italic;
    font-size: 20px;
}
</style>